@use "styles/globals.scss" as *;
.simpleHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding: 0 $margin;
  margin-bottom: 32px;
  width: 100%;
  min-height: 60px;

  @include cssVar(background-color, tertiary-white);
  @include fontHeadingMedium;

  a {
    width: 63px;
    height: 36px;
    margin-right: 18px;
  }
}
