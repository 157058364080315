@use "styles/globals.scss" as *;
.login {
  display: flex;
  justify-content: center;

  .inner {
    margin-top: 84px;
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: $margin * 2;

    @include mobile {
      position: absolute;
      top: 0;
      bottom: -350px; // Size of footer after bottom: 0;
      width: 100%;
      margin-top: 0;
      z-index: 2;

      @include cssVar(background, secondary-page-bg);
    }

    h1 {
      margin-bottom: 36px;

      @include fontHeadingLarge4;
    }

    h2 {
      @include fontHeadingLarge;
    }

    .forgot {
      margin-top: $margin / 2;

      @include fontBodyMedium;

      a {
        font-weight: bold;

        @include cssVar(color, primary-highlight);
      }
    }

    form {
      width: 368px;
      margin-top: 36px;

      @include mobile {
        width: 100%;
        padding: 0 $margin;
      }

      .checkbox {
        display: flex;
        flex-direction: row;

        div {
          width: min-content;
          margin-left: 8px;
        }

        input {
          width: min-content;
          height: min-content;
        }
      }

      button {
        width: 368px;
        height: 60px;

        @include fontLinkLarge2;

        @include mobile {
          width: 100%;
        }
      }

      .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .socials {
      margin-bottom: 30px;
      width: 100%;

      @include mobile {
        padding: 0 $margin;
      }

      a,
      button {
        width: 100%;
      }
    }
  }
}
