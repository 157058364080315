@use "styles/globals.scss" as *;
.error {
  display: flex;
  flex-direction: column;
  margin: 0 0 $margin * 0.5;
  padding: 12px 8px;
  color: $colorTertiaryError;
  border: 2px solid $colorTertiaryError;
  border-radius: 8px;
  align-items: center;

  .heading {
    display: flex;
    margin: 0;

    @include fontHeadingSmall;
  }

  .msg {
    display: flex;
    height: fit-content;
    margin-block-start: 0;
    margin-block-end: 0;

    @include fontBodyMedium;
  }
}
